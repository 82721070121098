import React from "react";
import "./Greeting.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import { greeting } from "../../portfolio";
import { Fade } from "react-reveal";
import FeelingProud from "./FeelingProud";
import { style } from "glamor";
import { handleClickScroll } from "../../utils/handleClickScroll";
import ButtonCTA from "../../components/ButtonCTA";
import { useHistory } from "react-router-dom";

export default function Greeting(props) {
  const theme = props.theme;

  const history = useHistory();
  const handleRedirect = (path) => {
    history.push(path)
  };
  // console.log(history.location.pathname);
  return (
    <Fade bottom duration={2000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <h1 className="greeting-text">{greeting.title}</h1>
            <p className="">{greeting.title2}</p>
            <div style={{ display: "flex" }}>
              <button onClick={() => handleRedirect("/divorce")}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  height="24"
                  width="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12 4a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-3 .5a4 4 0 0 0-1.1.7 6 6 0 0 0-1 1c-.2.3-.3.5-.6.6A3 3 0 0 1 5 7a1 1 0 1 0 0 2h1v.3l-1.9 4.2v.1A1.8 1.8 0 0 0 5.8 16h2.4a1.8 1.8 0 0 0 1.7-2.5L8 9.3V8a4.7 4.7 0 0 0 .7-.7c.1-.3.3-.4.5-.6l.2-.2c.4.6 1 1 1.6 1.3V20H5.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2H13V7.8a3 3 0 0 0 1.6-1.4l.5.3.9.8v1.8l-1.9 4.2v.1a1.8 1.8 0 0 0 1.7 2.4h2.4a1.8 1.8 0 0 0 1.7-2.5L18 9.3V9l1 .1a1 1 0 1 0 0-2c-.5 0-.7-.1-.9-.2a3 3 0 0 1-.7-.6L16.3 5l-1.4-.7a3 3 0 0 0-5.9 0Z"
                    clip-rule="evenodd"
                  />
                </svg>

                <span>
                  {" "}
                  <b> {"Divorcio Express"} </b>
                </span>
              </button>
              
              <button onClick={() => handleRedirect("/paternity")}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  height="24"
                  width="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12 4a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-3 .5a4 4 0 0 0-1.1.7 6 6 0 0 0-1 1c-.2.3-.3.5-.6.6A3 3 0 0 1 5 7a1 1 0 1 0 0 2h1v.3l-1.9 4.2v.1A1.8 1.8 0 0 0 5.8 16h2.4a1.8 1.8 0 0 0 1.7-2.5L8 9.3V8a4.7 4.7 0 0 0 .7-.7c.1-.3.3-.4.5-.6l.2-.2c.4.6 1 1 1.6 1.3V20H5.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2H13V7.8a3 3 0 0 0 1.6-1.4l.5.3.9.8v1.8l-1.9 4.2v.1a1.8 1.8 0 0 0 1.7 2.4h2.4a1.8 1.8 0 0 0 1.7-2.5L18 9.3V9l1 .1a1 1 0 1 0 0-2c-.5 0-.7-.1-.9-.2a3 3 0 0 1-.7-.6L16.3 5l-1.4-.7a3 3 0 0 0-5.9 0Z"
                    clip-rule="evenodd"
                  />
                </svg>

                <span>
                  {" "}
                  <b> {"Complemento por Paternidad"} </b>
                </span>
              </button>

            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}
