// Theme Presets

const lightTheme = {
  name: "light",
  body: "#FFFFFF",
  text: "#343434",
  dark: "#000000",
  secondaryText: "##006E12",
  accentColor: "#00AD1D",
  accentBright: "#00FA2A",
  projectCard: "#DCE4F2",
  skinColor: "#F7B799",
  skinColor2: "#FCB696",
  imageDark: "#dce4f2",
  imageClothes: "#dce4f2",
  avatarMisc: "#e9ecf2",
  avatarShoes: "#ccd2e3",
  sunHome:"#F7CC11",
};

const darkTheme = {
  name: "dark",
  body: "#1D1D1D",
  text: "#FFFFFF",
  dark: "#000000",
  secondaryText: "#8D8D8D",
  accentColor: "##00AD1D",
  accentBright: "#006E12",
  projectCard: "#292A2D",
  skinColor: "#F7B799",
  skinColor2: "#FCB696",
  imageDark: "#193D31",
  imageClothes: "#193D31",
  avatarMisc: "#193D31",
  avatarShoes: "#193D31",
  sunHome:"#89A2F3",
};

export const themes = { light: lightTheme, dark: darkTheme };

