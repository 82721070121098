import React, { useState, useEffect } from "react";
import "./Header.css";
import { Fade } from "react-reveal";
import { NavLink, Link } from "react-router-dom";
import { greeting, settings } from "../../portfolio.js";
import { CgSun } from "react-icons/cg/";
import { HiMoon } from "react-icons/hi";
import { style } from "glamor";
import { handleClickScroll } from "../../utils/handleClickScroll";
import { useHistory } from "react-router-dom";

function Header(props) {
  const theme = props.theme;
  const styles = style({
    cursor: "pointer",
    height: "45px",
    width: "45px",
    marginRight: "5px",
    marginLeft: "15px",
    paddingTop: "5px",
    marginTop: "5px",
    borderRadius: "50%",
    border: "none",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: props.theme.name === "light" ? "#7CD1F7" : "#292C3F",
    outline: "none",
    transition: "all 0.2s ease-in-out",
    ":hover": {
      boxShadow: `0 3px 8px ${
        props.theme.name === "light" ? "#F7D774" : "#646464"
      }`,
    },
  });
  const history = useHistory();
  const link = settings.isSplash ? "/splash" : "home";

  const [currTheme, setCurrTheme] = useState(props.theme);

  function changeTheme() {
    if (currTheme === "light") {
      props.setTheme("dark");
      localStorage.setItem("theme", "dark");
      setCurrTheme("dark");
    } else {
      props.setTheme("light");
      localStorage.setItem("theme", "light");
      setCurrTheme("light");
    }
  }

  const icon =
    props.theme.name === "dark" ? (
      <HiMoon
        strokeWidth={1}
        size={20}
        color={props.theme.name === "light" ? "#F9D784" : "#A7A7A7"}
      />
    ) : (
      <CgSun
        strokeWidth={1}
        size={20}
        color={props.theme.name === "light" ? "#F9D784" : "#A7A7A7"}
      />
    );
  //navbar scroll when active state
  const [navbar, setNavbar] = useState(false);

  //logo scroll when active

  const changeBackground = () => {
    if (window.scrollY >= 200) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
    // adding the event when scroll change Logo
  });

  return (
    <Fade top duration={1000} distance="20px" >
      <header
        className={["header", navbar ? "shadownheader" : ""].join(" ")}
        style={{
          backgroundColor: navbar
            ? props.theme.name === "light"
              ? "#fff"
              : "#232323"
            : "",
        }}
      >
        <div
          className="logo"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/")}
        >
          <span style={{ color: theme.text }}></span>
          <img
            className="logo-img"
            alt="logo"
            src={require("../../assests/images/logo.png")}
          />
          <span className="logo-name" style={{ color: theme.text }}>
            {greeting.logo_name}
          </span>
          <span style={{ color: theme.text }}></span>
        </div>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label className="menu-icon" htmlFor="menu-btn">
          <span className="navicon"></span>
        </label>
        <ul className="menu">
          <li
            className="homei"
            style={{ borderRadius: 5, color: theme.text }}
            onClick={() => handleClickScroll("skills")}
          >
            Quienes Somos
          </li>
          <li
            className="homei"
            style={{ borderRadius: 5, color: theme.text }}
            onClick={() => handleClickScroll("whatdoWedo")}
          >
            Que Hacemos
          </li>
          <li
            className="homei"
            style={{ borderRadius: 5, color: theme.text }}
            onClick={() => handleClickScroll("FAQs")}
          >
            FAQs
          </li>
          <li
            className="homei"
            style={{ borderRadius: 5, color: theme.text }}
            onClick={() => handleClickScroll("contact")}
          >
            Contacto
          </li>
          <li
            className="homei"
            style={{ borderRadius: 5, color: theme.text }}
            onClick={() => handleClickScroll("team")}
          >
            Equipo
          </li>
          {/* <button {...styles} onClick={changeTheme}>
            {icon}
          </button> */}
        </ul>
      </header>
    </Fade>
  );
}

export default Header;
