import React from 'react';

const PaternityComplementInfo = () => {
    return (
        <div style={{ padding: '20px' }}>
            <h1 style={{ textAlign: 'center',lineHeight: '1em'  }}>Todo sobre el complemento por paternidad en la jubilación</h1>
            
            <div style={{ padding: '2rem 20rem 2rem 20rem' }}>
                <p>Si eres pensionista, esto te interesa. El complemento de maternidad o paternidad se trata de un plus que se añade al importe de tu pensión si has tenido dos o más hijos.</p>

                <h2 style={{ textAlign: 'center' }}>¿El complemento afecta a todas las pensiones o sólo a las de jubilación ordinaria?</h2>
                <p>El complemento de paternidad se aplica a las pensiones contributivas, ya sea por incapacidad permanente, viudedad o jubilación (tanto anticipada como no anticipada) que se hayan otorgado a partir del 3 de febrero de 2021.</p>

                <h2 style={{ textAlign: 'center' }}>¿Quién se puede beneficiar de la pensión por paternidad?</h2>
                <p>Tienen derecho a solicitar el complemento de paternidad:</p>
                <ul>
                    <li>Los hombres que sean beneficiarios de una pensión contributiva por jubilación, incapacidad permanente o viudedad, y que sean padres de uno o más hijos o hijas, incluyendo padres de hijos adoptados. Este complemento también está disponible para las madres.</li>
                    <li>Los pensionistas que hayan comenzado a recibir una pensión contributiva por jubilación, viudedad o incapacidad permanente entre el 1 de enero de 2016 y el 3 de febrero de 2021. No es posible reclamarlo si la pensión se otorgó antes de 2016 o a partir del 4 de febrero de 2021.</li>
                </ul>

                <h2 style={{ textAlign: 'center' }}>¿Cuáles son los requisitos para poder cobrar el complemento de paternidad?</h2>
                <p>Si un padre desea solicitar el complemento de paternidad, debe cumplir con uno de los siguientes requisitos:</p>
                <ul>
                    <li>Tener derecho a recibir una pensión de viudedad debido al fallecimiento del otro progenitor, y los hijos comunes también tienen derecho a recibir una pensión de orfandad.</li>
                    <li>Haber obtenido una pensión contributiva por jubilación o incapacidad permanente y haber experimentado una interrupción o afectación en su carrera profesional como resultado del nacimiento o adopción de hijos, cumpliendo con las siguientes condiciones:</li>
                    <ul>
                        <li>Para hijos nacidos o adoptados antes del 31 de diciembre de 1994, haber acumulado más de 120 días sin cotización en los 9 meses previos al nacimiento y los 3 años posteriores a esa fecha, o en el caso de adopción, en los 3 años posteriores a la resolución judicial de adopción.</li>
                        <li>Para hijos nacidos o adoptados a partir del 1 de enero de 1995, la suma de las bases de cotización de los 24 meses posteriores al nacimiento o a la resolución judicial de adopción debe ser más de un 15% inferior a la suma de las bases de cotización de los 24 meses inmediatamente anteriores.</li>
                    </ul>
                    <li>En ambos casos, la suma de las pensiones otorgadas debe ser menor que la suma de las pensiones correspondientes a la madre.</li>
                </ul>

                <h2 style={{ textAlign: 'center' }}>¿En qué casos no se reconoce este complemento?</h2>
                <p>El derecho al complemento de paternidad no se otorgará a un padre (o madre) que haya perdido la patria potestad debido a una sentencia basada en el incumplimiento de sus deberes como padre o madre, o en casos de condena por delitos relacionados con cuestiones matrimoniales o criminales.</p>
                <p>Del mismo modo, este derecho no se concederá a un padre que haya sido condenado por cometer actos de violencia contra la mujer, particularmente contra la madre de los hijos, ni a un padre o madre que haya sido condenado por ejercer violencia contra sus propios hijos o hijas.</p>
            </div>
        </div>
    );
};

export default PaternityComplementInfo;
