import React from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import Home from "../pages/home/HomeComponent";
import Splash from "../pages/splash/Splash";
import Contact from "../pages/contact/ContactComponent";
import { settings } from "../portfolio.js";
import InitalPost from "../pages/initialPost";
import DivorceText from "../pages/initialPost/divorceText.js";
import PaternityComplementInfo from "../pages/initialPost/paternityText.js";
export default function Main(propss) {
  return (
    <div>
      <HashRouter basename="/">
        <Switch>
          <Route
            path="/"
            exact
            render={(props) => (
              <Splash
                {...props}
                theme={propss.theme}
                setTheme={propss.setTheme}
              />
            )}
          />
          <Route
            path="/home"
            render={(props) => (
              <Home
                {...props}
                theme={propss.theme}
                setTheme={propss.setTheme}
              />
            )}
          />
          <Route
            path="/divorce"
            render={(props) => (
              <InitalPost
                {...props}
                text={<DivorceText/>}
                image={"https://www.diariodesevilla.es/2023/04/04/sociedad/pareja-firma-acuerdo-divorcio_1780931938_180928991_1200x675.jpg"}
                theme={propss.theme}
                setTheme={propss.setTheme}
              />
            )}
          />
          <Route
            path="/paternity"
            render={(props) => (
              <InitalPost
                {...props}
                image={"https://www.occident.com/content/published/api/v1.1/assets/CONTEDC3BCF6661241D5B62CB7FF40C5EC57/Large?cb=_cache_1aa1&channelToken=6f9dd22fb10742ebb7b0bf0c874dd4c7&format=webp"}
                text={<PaternityComplementInfo/>}
                theme={propss.theme}
                setTheme={propss.setTheme}
              />
            )}
          />
          <Route
            path="/splash"
            render={(props) => (
              <Splash
                {...props}
                theme={propss.theme}
                setTheme={propss.setTheme}
              />
            )}
          />
        </Switch>
      </HashRouter>
    </div>
  );
}
