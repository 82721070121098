import React from "react";
import "./index.css";
function CardService({ onClick, title, body, buttonText }) {
  return (
    <div class="card">
      <div class="card-details">
        <p class="text-title">{title}</p>
        {body.map((textLine) => (
          <p class="text-body">{textLine}</p>
        ))}
      </div>
      <button class="card-button" onClick={onClick}>
        {buttonText}
      </button>
    </div>
  );
}

export default CardService;
