import React from "react";
import "./Contact.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import { contactInfo } from "../../portfolio";
import { skills } from "../../portfolio";
import { Fade } from "react-reveal";
// import DesignImg from "./DesignImg";

export default function Contact({ theme }) {
  return (
    <div className="main contact-margin-top" id="contact">
      {skills.data.map((skill, index) => {
        return (
          <div className="skills-main-div">
            <div
              className="skills-text-div article-who"
              style={{ margin: "0 auto" }}
            >
              <Fade left duration={1000}>
                <h2 className="skills-heading" style={{ color: theme.text }}>
                  {"Contacto"}
                </h2>
                <h1 className="skills-heading" style={{ color: theme.text }}>
                  {"¿Hablamos?"}
                </h1>
              </Fade>
              <Fade left duration={1000}>
                <div>
                  <p
                    className="subTitle skills-text"
                    style={{ color: theme.secondaryText, width: "95%" }}
                  >
                    {
                      "Puedes mandarnos un email utilizando este formulario o puedes llamarnos haciendo click en el siguiente botón:"
                    }
                  </p>
                </div>
              </Fade>
              <a href="tel:646352519" class="about-us-button"> 
              <svg style={{margin:"0px 10px"}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79zm9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75zM7.5 3H4c-.55 0-1 .45-1 1c0 9.39 7.61 17 17 17c.55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1c-1.24 0-2.45-.2-3.57-.57a.84.84 0 0 0-.31-.05c-.26 0-.51.1-.71.29l-2.2 2.2a15.149 15.149 0 0 1-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02A11.36 11.36 0 0 1 8.5 4c0-.55-.45-1-1-1"/></svg>
               LLÁMANOS </a>
              <a
                href="https://api.whatsapp.com/send/?phone=646352519&text=Hola%2C+me+pongo+en+contacto+contigo+para+contratar+sus+servicios+de+abogatría+especializados+en+..."
                class="about-us-button"
              ><span
              className="iconify"
              data-icon={"simple-icons:whatsapp"}
              data-inline="false"
              style={{margin:"0px 10px"}}
            ></span>
                WHATSAPP
              </a>
            </div>
            <Fade right duration={1000}>
              <div className="skills-image-div">
                <form
                  action="mailto:danielburruchaga@ysolve.es"
                  enctype="text/plain"
                >
                  <div>
                    <label for="email">Correo electrónico:</label>
                    <input
                      name="email"
                      type="text"
                      placeholder="tu-email@ejemplo.com"
                    />
                    <label for="subject">Asunto:</label>
                    <input
                      name="subject"
                      type="text"
                      placeholder="Escribe un asunto..."
                    />
                    <label for="body">Mensaje:</label>
                    <textarea
                      name="body"
                      id="body"
                      cols="30"
                      rows="5"
                      placeholder="Escribe un cuerpo..."
                    ></textarea>
                    <input class="submit" type="submit" value="Enviar" />
                  </div>
                </form>
              </div>
            </Fade>
          </div>
        );
      })}
    </div>
  );
}
