import React from "react";
import Header from "../../components/header/Header";
import Greeting from "../../containers/greeting/Greeting";
import Skills from "../../containers/skills/Skills";
import Contact from "../../containers/contact/Contact";
import OurTeam from "../../containers/ourteam/OurTeam";
import Footer from "../../components/footer/Footer";
import CookiePolicy from "../../components/CookiesPolicy";
import MisionVisionValores from "../../containers/misionVisionValores/MisionVisionValores";
import image from "../../assests/images/1.png";
import "./index.css";
function Home(props) {
  return (
    <div className="bgcolor" id="home">
      <div style={{ textAlign: "center", backgroundColor:"#EECDA6" }}>
          <p> Concierta tu primera cita GRATIS</p>
      </div>
      <Header theme={props.theme} setTheme={props.setTheme} />
      <Greeting theme={props.theme} />
      <Skills theme={props.theme} />
      {/* <MisionVisionValores theme={props.theme} /> */}
      <Contact theme={props.theme} />
      <OurTeam theme={props.theme} />
      <Footer theme={props.theme} />
      {/* <CookiePolicy /> */}
    </div>
  );
}

export default Home;
