import React from 'react';
import "./faq.css";

const faqData = [
    {
        "id": 1,
        "question": "¿Cuáles son los servicios legales que ofrecen?",
        "answer": "Nuestros servicios abarcan áreas como derecho civil, mercantil, laboral, penal, y asesoramiento en temas de propiedad intelectual."
    },
    {
        "id": 2,
        "question": "¿Tienen experiencia en casos de inmigración?",
        "answer": "Sí, contamos con un equipo especializado en leyes de inmigración, ayudando a clientes en trámites, visas y casos de deportación."
    },
    {
        "id": 3,
        "question": "¿Cuál es el proceso de tarifas y pagos?",
        "answer": "Nuestro bufete ofrece diferentes modalidades de tarifas, desde honorarios fijos hasta tarifas por hora, asegurando transparencia en los pagos."
    },
    {
        "id": 4,
        "question": "¿Pueden representarme en un litigio laboral?",
        "answer": "Sí, tenemos experiencia representando tanto a empleadores como a empleados en conflictos laborales, desde despidos hasta acuerdos colectivos."
    },
    {
        "id": 5,
        "question": "¿Ofrecen servicios de mediación?",
        "answer": "Sí, facilitamos la mediación en disputas, ofreciendo soluciones alternativas a los litigios para llegar a acuerdos mutuamente beneficiosos."
    },
    {
        "id": 6,
        "question": "¿Cuánto tiempo dura un proceso legal típico?",
        "answer": "La duración varía según la complejidad del caso y el tipo de proceso, pero nos esforzamos por gestionar eficientemente cada caso."
    },
    {
        "id": 7,
        "question": "¿Tienen experiencia en casos de propiedad intelectual?",
        "answer": "Sí, hemos representado a clientes en asuntos de patentes, marcas comerciales, derechos de autor y secretos comerciales."
    },
    {
        "id": 8,
        "question": "¿Cómo protegen la confidencialidad de mis asuntos legales?",
        "answer": "Nuestra firma se compromete a resguardar la confidencialidad de la información de nuestros clientes, siguiendo estrictas políticas de privacidad."
    },
    {
        "id": 9,
        "question": "¿Cuál es su enfoque en responsabilidad social corporativa?",
        "answer": "Nos comprometemos con prácticas de RSC, apoyando causas sociales y contribuyendo a comunidades a través de programas pro bono."
    },
    {
        "id": 10,
        "question": "¿Cuáles son sus credenciales y afiliaciones profesionales?",
        "answer": "Nuestros abogados están afiliados a diversas asociaciones profesionales y tienen credenciales sólidas en sus respectivas áreas de especialización."
    }
  ]
  
const FAQ = () => {
    return (
        <div className="faq-container">
            {faqData.map((item) => (
                <div className="faq-item" key={item.id} >
                    <details open={item.id === 1}>                        <summary>{item.question}</summary>
                        <p style={{padding:"25px"}}>{item.answer}</p>
                    </details>
                </div>
            ))}
        </div>
    );
};

export default FAQ;
