/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
  googleTrackingID: "",
};

//Home Page
const greeting = {
  title: "B.S.G ABOGADOS",
  title2:
    "Asesoramiento jurídico justo y cercano",
  button: "Entra a Mi Blog, YA",
  logo_name: "",
  nickname: "María Gimeno",
  full_name: "",
  resumeLink: "",
  mail: "danielburru@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/danielei9",
  linkedin: "https://www.linkedin.com/in/daniel-burruchaga//",
  gmail: "danielburru@gmail.com",
  gitlab: "",
  facebook: "",
  twitter: "",
  instagram: "https://www.instagram.com/buchspro/",
};
const aptitudes = [
  // {
  //   img: require("./assests/images/vision.png"),
  //   title: "Societario",
  //   subtitle: "Optimizando Estrategias para el Éxito Financiero",
  //   text:
  //   "Como artesanos de la ley, no solo redactamos contratos locales e internacionales, sino que también estudiamos meticulosamente cada posible consecuencia, garantizando así el mejor resultado posible en un mundo legal en constante evolución.",
  // },
  // {
  //   img: require("./assests/images/vision.png"),
  //   title: "Fiscal Contable",
  //   subtitle: "Optimizando Impuestos y Maximizando Ganancias",
  //   text:
  //     "Brindamos asesoramiento experto en la gestión fiscal y contable para sociedades. Maximizamos beneficios, gestionamos impuestos anuales, pagos fraccionados y aspectos transnacionales, asegurando una gestión impecable para tu empresa.",
  // },
  // {
  //   img: require("./assests/images/vision.png"),
  //   title: "Propiedad Intelectual",
  //   subtitle: "Marcas, Patentes, y Derechos Intelectuales: Asesoramiento Legal Completo",
  //   text:
  //     "Asesoramiento especializado en marcas, patentes, y derechos de autor. Desde el registro hasta la defensa legal, garantizamos protección integral para su propiedad intelectual e industrial. Con experiencia sólida, defendemos sus derechos frente a impugnaciones, embargos y más.",
  // },
  // {
  //   img: require("./assests/images/vision.png"),
  //   title: "Concursal",
  //   subtitle: "Abogados Especializados para Continuar Operaciones Comerciales",
  //   text:
  //   " El Derecho Concursal, vital en crisis financieras, permite a deudores y acreedores negociar acuerdos para pagar deudas sin liquidación. El convenio concursal, un pacto formal entre ambas partes, preserva la actividad comercial y el empleo, crucial en la reestructuración financiera."
  // },
  // {
  //   img: require("./assests/images/vision.png"),
  //   title: "Compliance penal",
  //   subtitle: "El impacto de la Responsabilidad Penal de Administradores en la Implementación del Compliance y la Transparencia Empresarial",
  //   text:"Responsabilidad de administradores era debatida. Desde entonces, la ley establece su responsabilidad por acciones penales de la sociedad. El Compliance, surgido en los 70, impone normas preventivas para evitar conductas ilícitas. Esto promueve transparencia y ayuda a prevenir delitos, ofreciendo a los reguladores más herramientas de fiscalización."
  // },
];

const skills = {
  data: [
    {
      pretitle: "¿Quienes Somos?",
      title: "B.S.G",
      fileName: "CloudInfraImg",
      skills: [
        `Contamos con un equipo de abogados con más de 25 años de experiencia en el sector.`,
        `Ofrecemos servicios jurídicos excepcionales y personalizados para garantizar la mejor solución para tus problemas legales.`,
        `Nuestra dedicación, experiencia y éxito comprobado nos convierten en la elección ideal para proteger tus derechos y resolver cualquier situación legal.`,
        `¡Haznos tu socio legal y benefíciate de décadas de experiencia en el campo de la ley!`,
      ],
    },
  ],
};
const misionVisionValores = [
  // {
  //   img: require("./assests/images/vision.png"),
  //   title: "Excelencia en la Preparación Legal",
  //   subtitle: "Metodología Efectiva",
  //   text:
  //     "Nuestro enfoque didáctico, basado en técnicas probadas, garantiza un aprendizaje efectivo y profundo para la preparación de oposiciones legales.",
  // },
  // {
  //   img: require("./assests/images/vision.png"),
  //   title: "Asesoramiento Especializado",
  //   subtitle: "Docentes Expertos",
  //   text:
  //   "Nuestro equipo de profesores altamente capacitados, con experiencia en oposiciones legales, ofrece orientación y conocimientos especializados para alcanzar el éxito."
  // },
  // {
  //   img: require("./assests/images/vision.png"),
  //   title: "Apoyo Integral para el Éxito",
  //   subtitle: "Recursos Completos",
  //   text:
  //   "Proporcionamos una plataforma integral con recursos actualizados, simulacros de exámenes y un acompañamiento cercano para asegurar el desarrollo y el éxito en las oposiciones legales."
  // },
];
const degrees = {
  degrees: [
    {
      title: "Técnico en Instalaciones de Telecomunicaciones",
      subtitle: "Grado medio Juan Comenius",
      logo_path: "Comenius.PNG",
      alt_name: "SSGC",
      duration: "2015 - 2017",
      descriptions: [
        "⚡ Montaje y mantenimiento de infraestructuras de telecomunicación ",
        "⚡ Instalaciones de circuito cerrado de televisión y seguridad electrónica,",
        "⚡ Centralitas telefónicas e infraestructuras de redes de voz y datos. ",
        "⚡ Instalaciones de radiocomunicaciones. ",
        "⚡ Sistemas domóticos y equipos informáticos. ",
      ],
      website_link:
        "https://www.comenius.es/?class=instalaciones-de-telecomunicaciones-2",
    },
    {
      title: "Técnico Superior mantenimiento electrónico",
      subtitle: "Grado Superior Juan Comenius",
      logo_path: "Comenius.PNG",
      alt_name: "SSGC",
      duration: "2017 - 2019",
      descriptions: [
        "⚡ Mantenimiento de equipos de radiocomunicaciones.",
        "⚡Mantenimiento de equipos de voz y datos.",
        "⚡Mantenimiento de equipos de audio.",
        "⚡Mantenimiento de equipos de vídeo.",
      ],
      website_link:
        "https://www.comenius.es/?class=mantenimiento-electronico-2",
    },
    {
      title: "Tecnologias Interactivas UPV",
      subtitle:
        "Grado en Ing Tecnologias Interactivas Universidad Politécnica de Valencia",
      logo_path: "UPV.png",
      alt_name: "UPV",
      duration: "2019 - Presente",
      descriptions: [
        "⚡ Diseño y programación Web",
        "⚡ Diseño Electrónico y programación firmware",
        "⚡ IOT / APPS (ios/android/web)",
        "⚡ Inteligencia Artificial y robótica",
        "⚡ Visión Artificial y Realiada Virtual",
        "⚡ Seguridad en redes",
      ],
      website_link: "https://www.upv.es/titulaciones/GTI/indexc.html",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Node,Express, React & MySql full stack web development",
      subtitle: "Udemy",
      logo_path: "react.png",
      certificate_link:
        "https://drive.google.com/file/d/1mxzOFWfg1hQIr_Is7gmJWeyW3BeDgvUe/view?usp=sharing",
      alt_name: "Udemy",
      color_code: "#2AAFED",
      //color_code: "#47A048",
    },
    {
      title: "Curso Completo de bases de datos MongoDB y NoSql",
      subtitle: "Udemy",
      logo_path: "mongo.png",
      certificate_link:
        "https://drive.google.com/file/d/1oLs-bvdgznRlg1qh2ocoDfEmFAsqkgyT/view?usp=sharing",
      alt_name: "Udemy",
      color_code: "#47A048",
      //color_code: "#E2405F",
    },
    {
      title: "Certificado Javascript",
      subtitle: "CodigoFacilito",
      logo_path: "javascript.png",
      certificate_link:
        "https://drive.google.com/file/d/1hJfxEuuj0E1MD62GPtjqSJsG7S4SbFdo/view?usp=sharing",
      alt_name: "CodigoFacilito",
      color_code: "#F6B808",
      //color_code: "#47A048",
    },
    {
      title: "Junior Pentester",
      subtitle: "TryHackMe",
      logo_path: "iit.png",
      certificate_link: "",
      alt_name: "TryHackMe",
      color_code: "#2AAFED",
    },
    {
      title: "Postman Student Expert",
      subtitle: "Postman",
      logo_path: "postman.png",
      certificate_link:
        "https://badgr.com/public/assertions/h2qevHs4SlyoHErJQ0mn2g",
      alt_name: "Postman",
      // color_code: "#f36c3d",
      color_code: "#fffbf3",
    },
    // color_code: "#8C151599",
    // color_code: "#7A7A7A",
    // color_code: "#0C9D5899",
    // color_code: "#C5E2EE",
    // color_code: "#ffc475",
    // color_code: "#g",
    // color_code: "#ffbfae",
    // color_code: "#fffbf3",
    // color_code: "#b190b0",
  ],
};

// Experience Page
const experience = {
  title: "Experiencias",
  subtitle: "Trabajos, erasmus, competiciones...",
  description: "",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Puestos de Trabajo",
      experiences: [
        {
          title: "Prácticas Ogenica i+d",
          company: "Ogenica",
          company_url: "http://Ogenica.com/",
          logo_path: "ogenica.jpg",
          duration: "Marzo 2016 - Junio 2016",
          location: "Burjassot Valencia",
          description: `
          Prácticas en Ogenica Ingenieria. Donde realicé un proyecto de estación de trabajo de electrónica: fuente de alimentación,voltimetro, amperímetro, ohmetro... y 
          todo conectado a una App android donde visualizar los datos.
          `,
          // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#FF29AD",
        },
        {
          title: "Técnico Superior",
          company: "Tecnosel",
          company_url: "http://tecnosel.com/",
          logo_path: "tecnosel.png",
          duration: "Marzo 2016 - Sept 2019",
          location: "Chirivella - Valencia",
          description: `Encargado de máquina SMT, CNC, horno de placas electrónicas, reparación, programación... LLegué a estar a cargo de la producción en masa de placas electrónicas,
          mediante una máquina SMT pick and place, y de todo el proceso de producción.
          `,
          // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#A80054",
        },
        {
          title: "Scrum Master / Desarrollador",
          company: "Gesinen S.L",
          company_url: "https://gesinen.es/",
          logo_path: "gesinen.jpg",
          duration: "Junio 2021 - Actual",
          location: "Remoto",
          description: `Scrum master, organizador de proyectos y desarrollador. Soluciones tecnológicas. Sobre todo trabajamos con protocolos de comunicación Lora / LTE / 4G.
          Firmware y web.
          `,
          color: "#FF29AD",
        },
      ],
    },
    {
      title: "Erasmus",
      experiences: [
        {
          title: "Erasmus + Precision",
          company: "Precision",
          company_url: "",
          logo_path: "Comenius.PNG",
          duration: "Junio 2015 - Sept 2015",
          location: "Lisboa - Portugal",
          description: `Se me brindó la ocasión de ir a una gran empresa en Lisboa, Portugal. Precision, donde me encargaba de la electronica de coches.
          `,
          color: "#A80054",
        },
      ],
    },
    {
      title: "Emprendimiento",
      experiences: [
        {
          title: "Proyecto Propio StartUp Ysolve",
          company: "Ysolve",
          company_url: "http://ysolve.es/",
          logo_path: "ysolve.png",
          duration: "abril 2021 - Actual",
          location: "Gandía - Valencia",
          description: `Desarrollo de soluciones tecnológicas, hemos realizado máquinas de auto pago, plataforma web, multiplataforma...
          `,
          // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#FF29AD",
        },
        {
          title: "Proyecto Propio StartUp M2U",
          company: "Music2U",
          company_url: "http://music2u.es/",
          logo_path: "music2u.png",
          duration: "junio 2021 - Actual",
          location: "Gandía - Valencia",
          description: `Desarrollo de empresa. Se basa en una App de inversion en musica y artistas. Con musica streming, blogs...
          `,
          // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#A80054",
        },
        {
          title: "Concurso Aula emprende",
          company: "UPV",
          company_url: "http://upv.es/",
          logo_path: "UPV.png",
          duration: "octubre 2022",
          location: "Gandía - Valencia",
          description: `Participación en concurso aula emprende Valencia UPV.
          `,
          // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#FF29AD",
        },
        {
          title: "Concurso 2k22 Instituto ideas",
          company: "UPV",
          company_url: "http://upv.es/",
          logo_path: "UPV.png",
          duration: "octubre 2022",
          location: "Gandía - Valencia",
          description: `Participación en concurso 2k22 Valencia UPV.
          `,
          // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#A80054",
        },
      ],
    },
    {
      title: "Competiciones",
      experiences: [
        {
          title: "1º Premio FP skills Valencia",
          company: "FP Skills",
          company_url: "",
          logo_path: "valencia.png",
          duration: "octubre 2017 ",
          location: "Valencia",
          description: "1º Premio en FP Skills Valencia",
          color: "#FF29AD",
        },
        {
          title: "3º Premio FP skills Comunidad Valenciana",
          company: "FP Skills",
          company_url: "",
          logo_path: "valencia.png",
          duration: "octubre 2017 ",
          location: "Alicante",
          description: "3º Premio en FP Skills Comunidad Valenciana",
          color: "#A80054",
        },
        {
          title: "Competición SigueLineas Comunidad Valenciana",
          company: "FP Skills",
          company_url: "",
          logo_path: "valencia.png",
          duration: "noviembre 2017 / 2018 / 2019",
          location: "Alcoi",
          description: "Participación en la competición siguelineas ",
          color: "#FF29AD",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Proyectos",
  description:
    "Mis proyectos tienen mucha variedad de tecnologias y herramientas. He realizado desde simples paginas web, app multiplataformas, plataformas web, sensores inteligentes, smart city Componentes, prototipado electrónico...Dejo algunos de mis trabajos aqui...",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contáctame",
    profile_image_path: "Daniel.jpg",
    description:
      "Puedes contactar conmigo desde cualquier método nombrado posteriormente. Trataré de contestarte lo mas rápido posible ;) ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "No suelo postear mucho pero a veces lo hago, podeis revisar mi trabajo día a día en instagram.",
    link: "https://instagram.com/",
    link2: "https://www.linkedin.com/in/",
    avatar_image_path: "blogs_image.svg",
  },
};
export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  aptitudes,
  misionVisionValores,
};
