import React from 'react';

const DivorceText = () => {
    return (
        <div style={{ padding: '20px' }}>
            <h1 style={{ textAlign: 'center',lineHeight: '1em' }} >Divorcio Express: Una Forma Rápida y Sencilla de Disolver tu Matrimonio</h1>
            <p style={{ textAlign: 'center' }}><strong>¿Estás buscando una forma rápida y sencilla de disolver tu matrimonio? El equipo de B.S.G te ayuda.</strong></p>
            <div style={{ padding: '2rem 20rem 2rem 20rem' }}>

            
            <h2 style={{ textAlign: 'center' }} >¿Qué es el divorcio express?</h2>
            <p>A diferencia de un proceso de divorcio contencioso, un divorcio express implica llegar a un pacto con tu pareja. Te guiará a través del proceso en línea y sin complicaciones.</p>
            <p>El «divorcio express» es un proceso legal simplificado que permite obtener el divorcio de manera más rápida y sencilla desde 2005.</p>
            
            <h2 style={{ textAlign: 'center' }} >¿Cuándo se puede solicitar el divorcio express?</h2>
            <p>- Cuando hayan transcurrido 3 meses desde la celebración del matrimonio y los cónyuges estén de acuerdo en el divorcio.</p>
            
            <h2 style={{ textAlign: 'center' }} >¿Qué documentos son necesarios para pedir el divorcio?</h2>
            <ul>
                <li>El certificado de matrimonio/Libro de familia.</li>
                <li>El certificado de nacimiento de los hijos.</li>
                <li>Certificado de empadronamiento de los cónyuges.</li>
            </ul>
            
            <h2 style={{ textAlign: 'center' }} >¿Cuáles son los requisitos que debemos cumplir para poder solicitar un divorcio?</h2>
            <ul>
                <li>Deben haber pasado tres o más meses desde que se produjo la celebración del matrimonio.</li>
                <li>Al menos uno de los cónyuges ha de tener su residencia en el Estado español.</li>
                <li>Ambos cónyuges deben estar de acuerdo en disolver el matrimonio.</li>
                <li>Debemos elaborar un Convenio regulador que explique la custodia y régimen de visitas de los hijos, reparto de bienes, pensión alimenticia, atribución del uso y disfrute del domicilio familiar, etc.</li>
            </ul>
            
            <h2 style={{ textAlign: 'center' }} >¿Cuáles son las ventajas del divorcio express?</h2>
            <p>Entre las ventajas del divorcio express destacan el ahorro de tiempo, dinero, demandas, molestias y emociones. En este proceso, los cónyuges tienen la oportunidad de resolver sus diferencias de manera más rápida y efectiva, lo que puede ser beneficioso para todos.</p>
            
            <p>Así pues, si necesitas contar con un abogado de divorcio en Valencia, visita nuestra web y contrata nuestros servicios sin perder más tiempo. Nuestros abogados expertos en divorcios y Derecho de familia se encargarán de tu asunto para que no tengas que preocuparte por nada en unos de los momentos más duros de tu vida, siempre velando por el interés superior de los hijos que haya.</p>
            </div>
        </div>
    );
};

export default DivorceText;
