import React from "react";
import "./Skills.css";
import SkillSection from "./SkillSection";
import FAQ from "./FAQ";
import { Fade } from "react-reveal";
import CardService from "../../components/cardService";

export default function Skills(props) {
  const theme = props.theme;
  return (
    <div className="main" id="skills">
      <SkillSection theme={theme} />
      <div className="skills-header-div ">
        <h2 className="skills-heading pretitle" style={{ color: theme.text }}>
          {"¿Que Hacemos?"}
        </h2>
        <Fade bottom duration={2000} distance="20px">
          <h1 className="skills-header" style={{ color: theme.text }} id="whatdoWedo">
            Áreas de Práctica
          </h1>
        </Fade>
      </div>
      <div className="container-service">
        <CardService
          title={"EXTRANJERÍA"}
          body={[
            "-	Cancelación de antecedentes penales",
            "-	Delitos contra la seguridad vial",
            "-	Violencia de genero",
            "-	Asistencia al detenido ",
          ]}
          buttonText={"Mas info"}
        ></CardService>
        <CardService
          title={"FAMILIA"}
          body={[
            "- Divorcio y separación",
            "- Modificación de medidas",
            "- Custodia compartida",
            "- Nulidad matrimonial",
          ]}
          buttonText={"Mas info"}
        ></CardService>
        <CardService
          title={"CIVIL"}
          body={[
            "- Arrendimientos",
            "- Contratos",
            "- Reclamación de deuda",
            "- Deshaucios",
          ]}
          buttonText={"Mas info"}
        ></CardService>
        <CardService
          title={"LABORAL"}
          body={[
            "- Prestaciones seguridad social",
            "- Reclamaciones y accidentes",
            "- Despidos",
          ]}
          buttonText={"Mas info"}
        ></CardService>
        <CardService
          title={"SUCESIONES"}
          body={[
            "- Asesoramiento jurídico en herencia y testamento",
            "- Desheredaciones",
            "- Impugnación testamentaria",
          ]}
          buttonText={"Mas info"}
        ></CardService>
        <CardService
          title={"SEGUROS Y ACCIDENTES"}
          body={[""]}
          buttonText={"Mas info"}
        ></CardService>
        <CardService
          title={"RESPONSABILIDAD CIVIL"}
          body={[""]}
          buttonText={"Mas info"}
        ></CardService>
        <CardService
          title={"PENAL"}
          body={[
            "- Cancelación de antecedentes",
            "- Delitos contra seguridad vial",
            "- Violencia de genero",
            "- Asistenccia al detenido",
          ]}
          buttonText={"Mas info"}
        ></CardService>
      </div>
      {/*** EMPIEZA FAQ */}
      <div className="skills-header-div">
        <h2 className="skills-heading pretitle" style={{ color: theme.text }}>
          {"FAQs"}
        </h2>
        <Fade bottom duration={2000} distance="20px">
          <h1 className="skills-header" id="FAQs" style={{ color: theme.text }}>
            Preguntas Frecuentes
          </h1>
        </Fade>
      </div>
      <FAQ theme={theme} />
      {/*** TERMINA FAQ */}
    </div>
  );
}
