import React from "react";
import Header from "../../components/header/Header.js";
import Footer from "../../components/footer/Footer.js";
import { Fade } from "react-reveal";
import "./ContactComponent.css";
import { style } from "glamor";

function InitalPost(props) {
  const theme = props.theme;

  const styles = style({
    backgroundColor: `${theme.accentBright}`,
    ":hover": {
      boxShadow: `0 5px 15px ${theme.accentBright}`,
    },
  });

  return (
    <div className="contact-main">
      <Header theme={theme} setTheme={props.setTheme} />
      <img
        src={props.image}
        alt="Imagen de un acuerdo de divorcio"
        style={{
          width: "100%", // La imagen ocupará el ancho completo del contenedor
          height: "40rem", // Establece la altura deseada
          objectFit: "cover", // Permite recortar la imagen y mantener su proporción de aspecto
          opacity: "70%",
        }}
      ></img>

      <div className="basic-contact">
        <Fade bottom duration={1000} distance="40px"></Fade>
        <Fade bottom duration={1000} distance="40px">
          {props.text}
        </Fade>
      </div>
      <Footer theme={props.theme} onToggle={props.onToggle} />
    </div>
  );
}

export default InitalPost;
