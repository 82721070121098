import React from "react";
import "./Footer.css";
import { Fade } from "react-reveal";
import { greeting } from "../../portfolio.js";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
/* eslint-disable jsx-a11y/accessible-emoji */
import { handleClickScroll } from "../../utils/handleClickScroll";

export default function Footer(props) {
  return (
    <footer class="footer-distributed">
      <div class="footer-left">
        <img
          className="logo-img-footer"
          alt="logo"
          src={require("../../assests/images/logo.png")}
        />

        <p class="footer-links">
          <a onClick={() => handleClickScroll("home")}>Home</a>
          <a onClick={() => handleClickScroll("skills")}>Quienes somos</a>
          <a onClick={() => handleClickScroll("whatdoWedo")}>Que hacemos</a>
          <a onClick={() => handleClickScroll("FAQs")}>FAqs</a>
          <a onClick={() => handleClickScroll("contact")}>Contacto</a>
          <a onClick={() => handleClickScroll("team")}>Equipo</a>
        </p>

        <p class="footer-company-name">BSG Abogados © 2021</p>
        <p class="footer-company-name">
          Developed by <a href="https://ysolve.es">Ysolve</a>
        </p>
      </div>

      <div class="footer-center">
        <div>
          <i class="fa fa-map-marker"></i>
          <p>
            <span> C/ Garrigues 15, Puerta 7 46001 </span> Valencia, España
          </p>
        </div>

        <div>
          <a href="https://wa.me/604934292" target={"_blank"}>
            <i class="fa fa-phone"></i>
            <p>646 35 25 19 </p>
          </a>
        </div>

        <div>
          <i class="fa fa-envelope"></i>
          <p>
            <a href="mailto:info@ysolve.es">TODO: email</a>
          </p>
        </div>
      </div>

      <div class="footer-right">
        <p class="footer-company-about">
          <span>Sobre BSG Abogados</span>
          Contamos con un equipo de abogados con más de 25 años de experiencia en el sector.

Ofrecemos servicios jurídicos excepcionales y personalizados para garantizar la mejor solución para tus problemas legales.

Nuestra dedicación, experiencia y éxito comprobado nos convierten en la elección ideal para proteger tus derechos y resolver cualquier situación legal.
        </p>

        <div class="footer-icons">
          <a href=" https://www.instagram.com/bsgabogadosvlc" target={"_blank"}>
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltip-top`}>
                  <strong>{"instagram"}</strong>
                </Tooltip>
              }
            >
              <li
                style={{
                  listStyle: "none",
                  fontSize: "20px",
                }}
                name={"instagram"}
              >
                <span
                  className="iconify"
                  data-icon={"simple-icons:instagram"}
                  data-inline="false"
                ></span>
              </li>
            </OverlayTrigger>
          </a>
        </div>
      </div>
    </footer>
  );
}
