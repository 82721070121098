import React from "react";
import "./OurTeam.css";
import { Fade } from "react-reveal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export default function OurTeam(props) {
  const history = useHistory();
  const theme = props.theme;
  return (
    <div className="main" id="team" s>
      <div className="skills-header-div ">
        <h2 className="skills-heading pretitle" style={{ color: theme.text }}>
          {"Nuestro Equipo"}
        </h2>
        <Fade bottom duration={1000} distance="20px">
          <h1 className="skills-header" style={{ color: theme.text }}>
            Nuestro Equipo
          </h1>
        </Fade>
      </div>
      <div
        className="main-container-team"
        onClick={() => history.push("/contact")}
      >
        <Fade left duration={1000} distance="300px">
          <div className="container-integrant">
            <div className="container-img-integrant">
              <img
                alt="Irene Sanz González"
                src={require("../../assests/images/equipoB.png")}
                style={{borderRadius:"10px"}}
                ></img>
            </div>
            <h2 style={{padding:"10px"}}>Irene Sanz González</h2>
            <h3>
            Abogada especialista en derecho laboral, accidentes, responsabilidad civil y corredor de seguros.
            </h3>
            <div className="container-socials-integrant">
              <a href="https://www.linkedin.com/" target={"_blank"}>
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      <strong>{"Linkedin"}</strong>
                    </Tooltip>
                  }
                >
                  <li
                    style={{
                      listStyle: "none",
                      marginTop: "0.75rem",
                      fontSize: "25px",
                      marginLeft: "1rem",
                    }}
                    name={"Linkedin"}
                  >
                    <span
                      className="iconify"
                      data-icon={"simple-icons:linkedin"}
                      data-inline="false"
                    ></span>
                  </li>
                </OverlayTrigger>
              </a>
              <a href="https://wa.me/646352519" target={"_blank"}>
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      <strong>{"Whatsapp"}</strong>
                    </Tooltip>
                  }
                >
                  <li
                    style={{
                      listStyle: "none",
                      marginTop: "0.75rem",
                      fontSize: "25px",
                      marginLeft: "1rem",
                    }}
                    name={"Whatsapp"}
                  >
                    <span
                      className="iconify"
                      data-icon={"simple-icons:whatsapp"}
                      data-inline="false"
                    ></span>
                  </li>
                </OverlayTrigger>
              </a>
            </div>
          </div>
        </Fade>
        <Fade left duration={1000} distance="300px">
          <div className="container-integrant">
            <div className="container-img-integrant">
              <img
                alt="Federico Bisquert Bernabeu"
                src={require("../../assests/images/equipoA.png")}
                style={{borderRadius:"10px"}}
              ></img>
            </div>
            <h2 style={{padding:"10px"}}>Federico Bisquert Bernabeu</h2>
            <h3> Abogado y mediador especialista en civil, penal y extranjería.</h3>
            <div className="container-socials-integrant">
              <a href="https://www.linkedin.com/" target={"_blank"}>
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      <strong>{"Linkedin"}</strong>
                    </Tooltip>
                  }
                >
                  <li
                    style={{
                      listStyle: "none",
                      marginTop: "0.75rem",
                      fontSize: "25px",
                      marginLeft: "1rem",
                    }}
                    name={"Linkedin"}
                  >
                    <span
                      className="iconify"
                      data-icon={"simple-icons:linkedin"}
                      data-inline="false"
                    ></span>
                  </li>
                </OverlayTrigger>
              </a>
              <a href="https://wa.me/646352519" target={"_blank"}>
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      <strong>{"Whatsapp"}</strong>
                    </Tooltip>
                  }
                >
                  <li
                    style={{
                      listStyle: "none",
                      marginTop: "0.75rem",
                      fontSize: "25px",
                      marginLeft: "1rem",
                    }}
                    name={"Whatsapp"}
                  >
                    <span
                      className="iconify"
                      data-icon={"simple-icons:whatsapp"}
                      data-inline="false"
                    ></span>
                  </li>
                </OverlayTrigger>
              </a>
            </div>
          </div>
        </Fade>
        {/* <Fade left duration={1000} distance="300px">
          <div className="container-integrant">
            <div className="container-img-integrant">
              <img
                alt="María Gimeno Icon"
                src={require("../../assests/images/equipoB.png")}
              ></img>
            </div>
            <h2>María Gimeno</h2>
            <h3>
              Abogada, y experta escritora de temas relacionados con abogatría y
              leyes.
            </h3>
            <div className="container-socials-integrant">
              <a href="https://www.linkedin.com/" target={"_blank"}>
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      <strong>{"Linkedin"}</strong>
                    </Tooltip>
                  }
                >
                  <li
                    style={{
                      listStyle: "none",
                      marginTop: "0.75rem",
                      fontSize: "25px",
                      marginLeft: "1rem",
                    }}
                    name={"Linkedin"}
                  >
                    <span
                      className="iconify"
                      data-icon={"simple-icons:linkedin"}
                      data-inline="false"
                    ></span>
                  </li>
                </OverlayTrigger>
              </a>
              <a href="https://wa.me/646352519" target={"_blank"}>
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      <strong>{"Whatsapp"}</strong>
                    </Tooltip>
                  }
                >
                  <li
                    style={{
                      listStyle: "none",
                      marginTop: "0.75rem",
                      fontSize: "25px",
                      marginLeft: "1rem",
                    }}
                    name={"Whatsapp"}
                  >
                    <span
                      className="iconify"
                      data-icon={"simple-icons:whatsapp"}
                      data-inline="false"
                    ></span>
                  </li>
                </OverlayTrigger>
              </a>
            </div>
          </div>
        </Fade> */}
      </div>
    </div>
  );
}
